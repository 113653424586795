import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Aerial,
  Agent,
  Email,
  Labels,
  Nudge,
  Robot,
  SpeechBubble,
  TreandingUp as TrendingUp,
  UserCheck,
} from 'frontend/assets/icons';
import { convertFormValuesToQueryParams } from 'frontend/features/Inbox/components/InboxFilters/utils/helpers';
import SubNav from 'frontend/features/SubNav/SubNav';
import { useLinkTo } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './NewAnalytics.scss';
import AnalyticsNavLink from './components/AnalyticsNavLink';
import { EmbeddedAnalytics } from '../../components';
import { initialAnalyticsFilter } from '../../components/EmbeddedAnalytics/EmbeddedAnalytics';
import { LUZMO_DASHBOARDS } from '../../components/EmbeddedAnalytics/constants';

const NewAnalytics = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { botId } = useParams();

  const { isKindlyAdminManager } = useMyPermissions({ botId });

  const currentRouteSlug = pathname.split('/').pop();

  const areDashboardQueryParamsGroupEqual = (currentSlug: string, nextSlug: string): boolean => {
    let currSlugQueryGroup: unknown;
    let nextSlugQueryGroup: unknown;

    Object.values(LUZMO_DASHBOARDS).forEach((dashboard) => {
      if (dashboard.route === currentSlug) {
        currSlugQueryGroup = dashboard.queryParamsGroup;
      }
      if (dashboard.route === nextSlug) {
        nextSlugQueryGroup = dashboard.queryParamsGroup;
      }
    });

    // If at least one of the slugs is a dashboard, we compare the `queryParamsGroup`
    if (currSlugQueryGroup || nextSlugQueryGroup) {
      return currSlugQueryGroup === nextSlugQueryGroup;
    }

    return false;
  };

  const NEW_ANALYTICS_MENU_ITEMS: {
    name: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    slug: string;
    className?: string;
    hide?: boolean;
    keepQuery?: boolean;
    queryParams?: string;
  }[] = [
    {
      name: 'Bot Dashboard',
      icon: SpeechBubble,
      slug: LUZMO_DASHBOARDS.BOT_DASHBOARD.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.BOT_DASHBOARD.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },
    {
      name: 'Containment rate',
      icon: Aerial,
      slug: LUZMO_DASHBOARDS.CONTAINMENT_RATE.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.CONTAINMENT_RATE.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },
    {
      name: 'Behavior',
      icon: Agent,
      slug: LUZMO_DASHBOARDS.BEHAVIOR.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.BEHAVIOR.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },
    {
      name: 'Labels',
      icon: Labels,
      slug: LUZMO_DASHBOARDS.LABELS.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.LABELS.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },
    {
      name: 'Usage',
      icon: TrendingUp,
      slug: LUZMO_DASHBOARDS.USAGE.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.USAGE.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },
    {
      name: 'Kindly GPT',
      icon: Robot,
      slug: LUZMO_DASHBOARDS.KINDLY_GPT.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.KINDLY_GPT.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },
    {
      name: 'Authenticated',
      icon: UserCheck,
      slug: LUZMO_DASHBOARDS.AUTHENTICATED.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.AUTHENTICATED.route)
        ? { keepQuery: true }
        : {
            queryParams: convertFormValuesToQueryParams(initialAnalyticsFilter),
          }),
    },

    {
      name: 'Email Overview',
      icon: Email,
      slug: LUZMO_DASHBOARDS.EMAIL_OVERVIEW.route,
      className: 'kindlyAdminManager',
      hide: !isKindlyAdminManager,
    },
    {
      name: 'Nudge Overview',
      icon: Nudge,
      slug: LUZMO_DASHBOARDS.NUDGE_OVERVIEW.route,
      className: 'kindlyAdminManager',
      hide: !isKindlyAdminManager,
    },
  ];

  const defaultTo = useLinkTo(NEW_ANALYTICS_MENU_ITEMS[0]?.slug, {
    keepQuery: !!NEW_ANALYTICS_MENU_ITEMS[0]?.keepQuery,
    queryParams: NEW_ANALYTICS_MENU_ITEMS[0]?.queryParams,
  });

  const handleNudgeRowClick = () => {
    navigate(LUZMO_DASHBOARDS.NUDGE_DETAILS.route);
  };

  const handleEmailRowClick = () => {
    navigate(LUZMO_DASHBOARDS.EMAIL_DETAILS.route);
  };

  return (
    <div className={styles.newAnalyticsWrapper}>
      <div className={styles.newAnalyticsMenu}>
        <SubNav>
          {NEW_ANALYTICS_MENU_ITEMS.map(
            ({ name, icon, slug, className, hide, keepQuery, queryParams }) =>
              !hide && (
                <AnalyticsNavLink
                  key={slug}
                  title={name}
                  icon={icon}
                  slug={slug}
                  className={className}
                  keepQuery={keepQuery}
                  queryParams={queryParams}
                />
              ),
          )}
        </SubNav>
      </div>
      <div className={styles.newAnalyticsContentWrapper}>
        <Routes>
          <Route
            path={LUZMO_DASHBOARDS.BOT_DASHBOARD.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.BOT_DASHBOARD.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.BOT_DASHBOARD.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.CONTAINMENT_RATE.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.CONTAINMENT_RATE.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.CONTAINMENT_RATE.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.BEHAVIOR.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.BEHAVIOR.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.BEHAVIOR.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.USAGE.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.USAGE.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.USAGE.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.LABELS.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.LABELS.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.LABELS.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.KINDLY_GPT.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.KINDLY_GPT.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.KINDLY_GPT.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.AUTHENTICATED.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.AUTHENTICATED.luzmoSlug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.AUTHENTICATED.luzmoSlug}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.luzmoSlug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.luzmoSlug}
                shouldDisplayFilters={false}
                customEvent={handleEmailRowClick}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.EMAIL_DETAILS.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.EMAIL_DETAILS.luzmoSlug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.EMAIL_DETAILS.luzmoSlug}
                shouldDisplayFilters={false}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.luzmoSlug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.luzmoSlug}
                shouldDisplayFilters={false}
                customEvent={handleNudgeRowClick}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.NUDGE_DETAILS.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.NUDGE_DETAILS.luzmoSlug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.NUDGE_DETAILS.luzmoSlug}
                shouldDisplayFilters={false}
              />
            }
          />
          <Route path="/" element={<Navigate to={defaultTo} replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default NewAnalytics;
