/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import type { ConnectDropTarget } from 'react-dnd';
import { useNavigate } from 'react-router-dom';

import {
  Checklist,
  Entities,
  Proactivity,
  Settings,
  SpeechBubble,
  TreandingUp as TrendingUp,
} from 'frontend/assets/icons';
import { List } from 'frontend/components';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { BotImprovementCount, RecentlyUsed } from 'frontend/features/Library/components';
import { useOnDragHover } from 'frontend/features/Library/hooks';
import { useBotOrSkill } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './NavigationView.scss';

interface NavigationViewProps {
  setCurrentView: React.Dispatch<React.SetStateAction<keyof typeof LibraryViewNavEnum>>;
}

export enum LibraryViewNavEnum {
  DIALOGUE = 'DIALOGUE',
  NAVIGATION = 'NAVIGATION',
  SYSTEM = 'SYSTEM',
  BOT_IMPROVEMENTS = 'BOT_IMPROVEMENTS',
  ENTITIES = 'ENTITIES',
  EVALUATION = 'EVALUATION',
  SEARCH = 'SEARCH',
  PROACTIVITY = 'PROACTIVITY',
}

export type NavigationProperties = {
  title: string;
  key: string;
  hasPermissions?: string;
  icon?: React.ReactElement;
  getUrl?: (args: { botId?: string; skillId?: string }) => string;
  showOnSkill?: boolean;
  rightContent?: React.ReactElement;
  hideFromMenu?: boolean;
};

export type LibraryMainNavigation = Partial<Record<keyof typeof LibraryViewNavEnum, NavigationProperties>>;

export const navigationPages: LibraryMainNavigation = {
  [LibraryViewNavEnum.NAVIGATION]: {
    title: 'Library',
    key: 'library-navigation',
    hideFromMenu: true,
  },
  [LibraryViewNavEnum.SEARCH]: {
    title: '',
    key: 'library-search',
    hideFromMenu: true,
  },
  [LibraryViewNavEnum.DIALOGUE]: {
    title: 'Dialogues',
    showOnSkill: true,
    key: 'library-dialogues',
    icon: <SpeechBubble />,
    getUrl: ({ botId, skillId }) => `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/dialogues`,
  },
  [LibraryViewNavEnum.SYSTEM]: {
    title: 'System dialogues',
    key: 'library-system',
    icon: <Settings />,
    getUrl: ({ botId }) => `/workspace/${botId}/build/system-dialogues`,
  },
  [LibraryViewNavEnum.BOT_IMPROVEMENTS]: {
    title: 'Bot improvements',
    key: 'library-bot-improvements',
    icon: <TrendingUp />,
    getUrl: ({ botId, skillId }) => `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/bot-improvements`,
    rightContent: <BotImprovementCount />,
  },
  [LibraryViewNavEnum.PROACTIVITY]: {
    title: 'Proactivity',
    key: 'library-proactivity',
    icon: <Proactivity />,
    getUrl: ({ botId, skillId }) => `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/proactivity`,
  },
  [LibraryViewNavEnum.ENTITIES]: {
    title: 'Entities',
    key: 'library-entities',
    hasPermissions: 'view_templates',
    showOnSkill: true,
    icon: <Entities />,
    getUrl: ({ botId, skillId }) => `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/entities`,
  },

  [LibraryViewNavEnum.EVALUATION]: {
    title: 'Evaluation',
    key: 'library-evaluation',
    hasPermissions: 'manage_evaluation',
    icon: <Checklist />,
    getUrl: ({ botId, skillId }) => `/${botId ? 'workspace' : 'skill'}/${botId || skillId}/build/evaluation`,
  },
};

export default function NavigationView({ setCurrentView }: NavigationViewProps) {
  const { isBot, skillId, botId, buildIdObject } = useBotOrSkill();
  const { botSettings } = useBotSettings(botId);
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const navigate = useNavigate();

  const [dialoguesRef, dialoguesCanDrop] = useOnDragHover(() => navigate('dialogues'));
  const [systemRef, systemCanDrop] = useOnDragHover(() => navigate('system-dialogues'));

  if (loadingPermissions) {
    return null;
  }

  const checkConditions = (item: NavigationProperties) => {
    if (item.key === 'library-entities' && botSettings?.intentMatchEnabled) {
      return false;
    }
    // We check if there is a condition to be shown only in workspace
    if (isBot && botId && (!item.hasPermissions || hasBotPerms(item.hasPermissions))) {
      return true;
    }
    // If we should show on skill
    if (skillId && item.showOnSkill) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className={styles.navigationContainer}>
        <List
          dataSource={Object.entries(navigationPages)}
          renderItem={([key, item]: [keyof typeof LibraryViewNavEnum, NavigationProperties]) =>
            checkConditions(item) && !item.hideFromMenu ? (
              <List.NavLinkItem
                ignoreActive
                key={item.key}
                className={cx({
                  [styles.navigationCardNoDrop]: dialoguesCanDrop || systemCanDrop,
                  [styles.navigationCardCanDrop]:
                    (key === LibraryViewNavEnum.DIALOGUE && dialoguesCanDrop) ||
                    (key === LibraryViewNavEnum.SYSTEM && systemCanDrop),
                })}
                onClick={() => setCurrentView(key as keyof typeof LibraryViewNavEnum)}
                {...(key === LibraryViewNavEnum.DIALOGUE && { ref: dialoguesRef as ConnectDropTarget })}
                {...(key === LibraryViewNavEnum.SYSTEM && { ref: systemRef as ConnectDropTarget })}
                to={item.getUrl?.({ botId, skillId }) as string}
              >
                <div className={styles.navigationElContainer}>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
                <div className={styles.navigationRightContent}>{item.rightContent}</div>
              </List.NavLinkItem>
            ) : null
          }
        />
      </div>
      <RecentlyUsed buildIdObject={buildIdObject!} />
    </>
  );
}
