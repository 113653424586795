import { gql } from '@apollo/client';

import { botSettingsFragment } from 'frontend/api/fragments';

export default gql`
  mutation UpdateBot($botId: ID!, $botInput: BotInputType!) {
    updateBot(botId: $botId, botInput: $botInput) {
      ...BotSettings
    }
  }
  ${botSettingsFragment}
`;
