import { gql } from '@apollo/client';

import { botSettingsFragment, webhookSigningApiKeyFragment } from 'frontend/api/fragments';

export const GET_WEBHOOK_SIGNING_KEY = gql`
  query GetWebhookSigningKey($botId: ID!) {
    webhookSigningKey(botId: $botId) {
      ...WebhookSigningKey
    }
  }
  ${webhookSigningApiKeyFragment}
`;

export const REGENERATE_WEBHOOK_SIGNING_KEY = gql`
  mutation RegenerateWebhookSigningKey($botId: ID!) {
    regenerateWebhookSigningKey(botId: $botId) {
      ...WebhookSigningKey
    }
  }
  ${webhookSigningApiKeyFragment}
`;

export const UPDATE_SECURITY_SETTINGS = gql`
  mutation UpdateSecuritySettings($botId: ID!, $botInput: BotInputType!) {
    updateBot(botId: $botId, botInput: $botInput) {
      ...BotSettings
    }
  }
  ${botSettingsFragment}
`;

export const GET_SECURITY_SETTINGS = gql`
  query GetSecuritySettings($botId: ID!) {
    bot(id: $botId) {
      ...BotSettings
    }
  }
  ${botSettingsFragment}
`;
