import { useMutation, useQuery } from '@apollo/client';
import { mapKeys, omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { updateBotSettings } from 'frontend/api/mutations';
import { getBotSettings } from 'frontend/api/queries';
import { KINDLY_ENTITIES } from 'frontend/features/Entities/queries';
import { useIsRunning, useOnSubmitWithUpload, useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { capitalize } from 'frontend/utils';

import { IGNORED_BOT_SETTINGS } from '../constants';

const flattenNestedSettings = (settings, prefix) =>
  mapKeys(omit(settings, IGNORED_BOT_SETTINGS), (_, key) => `${prefix}${capitalize(key)}`);

export default () => {
  const toast = useToast();
  const { botId } = useParams();

  const update = useCallback(() => {
    toast.success('Settings updated!');
  }, [toast]);

  const [updateSettings] = useMutation(updateBotSettings, { update });

  const { data: settingsData, loading: botSettingsLoading } = useQuery(getBotSettings, { variables: { botId } });
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });

  const canViewTemplates = hasBotPerms('view_templates');

  const loading = botSettingsLoading || loadingPermissions;

  const initialValues = useMemo(() => {
    if (loading || !settingsData) {
      return {};
    }
    const { nlpSettings, ...rest } = settingsData;

    return {
      ...rest,
      ...flattenNestedSettings(nlpSettings, 'nlp'),
    };
  }, [loading, settingsData]);

  const onSubmit = useCallback(
    async ({ bot }) => {
      await updateSettings({
        variables: {
          botId,
          botInput: omit(
            {
              ...bot,
              ...flattenNestedSettings(bot.nlpSettings, 'nlp'),
              deleteAvatar: bot.avatarUrl === null,
            },
            IGNORED_BOT_SETTINGS,
          ),
        },
        refetchQueries: ({ data }) =>
          canViewTemplates
            ? (data?.updateBot?.languages ?? []).map(({ code }) => ({
                query: KINDLY_ENTITIES,
                variables: { botId, languageCode: code },
              }))
            : [],
      });
    },
    [canViewTemplates, botId, updateSettings],
  );

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, `api/v2/bot/${botId}/upload-avatar/`);
  const [onSubmitWithRunning, isSubmitting] = useIsRunning(onSubmitWithUpload);

  return {
    initialValues,
    settingsData,
    loading,
    onSubmit: onSubmitWithRunning,
    setFile,
    isSubmitting,
  };
};
