import { gql } from '@apollo/client';

import zodiacSettingsFragment from './ZodiacSettings.fragment.graphql';

export default gql`
  fragment BotSettings on BotType {
    id
    name
    projectName
    primaryLanguage
    avatarUrl
    chatbubbleDomains
    permissions
    chatbubbleHeartbeatEnabled
    globalIconsEnabled
    intentMatchEnabled
    kindlyEntitiesEnabled
    kindlyGptSettingsEnabled
    publishingMode
    slotsEnabled
    suggestionsEnabled
    whitelabelEnabled
    reserveAgainstArchiving
    organization {
      id
    }
    languages {
      id
      code
      name
    }
    zodiacSettings {
      ...ZodiacSettings
    }
    nlpSettings {
      id
      confidenceThresholds
      useSpellingCorrection
      spellingCorrectionWhitelist
      mimirEnabledLanguageMap
      phaticExpressionEnabled
      memoryEnabled
      crossLanguagePrediction
    }
  }
  ${zodiacSettingsFragment}
`;
