import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { Button, PageBar } from 'frontend/components';
import { useDraftEnabled } from 'frontend/hooks';

import styles from './DialoguePageBar.scss';

const DialoguePageBar = ({ hasChanges, followUpCount, isNewDialogue }) => {
  const { draftEnabled, draftText } = useDraftEnabled();
  const [searchParamsFlow] = useSearchParams({ view: 'flow' });
  const [hasChangesText, noChangesText] = (draftEnabled && draftText) || [];
  const flowButtonText = followUpCount ? `View flow (${followUpCount})` : 'View flow';

  return (
    <PageBar className={styles.pageBar}>
      {!isNewDialogue && <Button to={`?${searchParamsFlow.toString()}`} text={flowButtonText} />}
      <PageBar.FormButtons hasChanges={hasChanges} hasChangesText={hasChangesText} noChangesText={noChangesText} />
    </PageBar>
  );
};

DialoguePageBar.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
  followUpCount: PropTypes.number,
  isNewDialogue: PropTypes.bool,
};

export default DialoguePageBar;
