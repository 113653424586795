import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { getBotSettings } from 'frontend/api/queries';

export default (botId) => {
  if (!botId) return {};
  const { loading, data } = useQuery(getBotSettings, { variables: { botId } });
  const botSettings = get(data, 'bot');

  return { botSettings, loading };
};
