import { gql } from '@apollo/client';

import { botSettingsFragment } from 'frontend/api/fragments';

export default gql`
  query GetBotSettings($botId: ID!) {
    bot(id: $botId) {
      ...BotSettings
    }
  }
  ${botSettingsFragment}
`;
