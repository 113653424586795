import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BotDocument, MyOrganizationsDocument } from 'frontend/api/generated';
import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import { Button, Loader, Panel, ToggleSwitch } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import type { Language } from 'frontend/types/language';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './Advanced.scss';
import { useBotSettings, useGeneralForm } from '../../../hooks';
import ArchiveBot from '../../modals/ArchiveBot';
import CloneBot from '../../modals/CloneBot';
import CreateSkillFromBot from '../../modals/CreateSkillFromBot';
import DeleteBot from '../../modals/DeleteBot';
import TransferToOrganization from '../../modals/TransferToOrganization';
import TranslateBot from '../../modals/TranslateBot';

const getLanguageNames = (languages: Language[]) => languages.map(({ name }): string => name).join(', ');

const RESERVE_AGAINTS_ARCHIVING_STATUS = 'Reserve against auto-archiving';

const Advanced = () => {
  const { botId } = useParams();
  const [showArchiveBot] = useModal(ArchiveBot);
  const [showDeleteBot] = useModal(DeleteBot);
  const [showCloneBot] = useModal(CloneBot);
  const [showTransferToOrganization] = useModal(TransferToOrganization);
  const [showTranslateBot] = useModal(TranslateBot);
  const [showCreateSkillFromBot] = useModal(CreateSkillFromBot);

  const { hasBotPerms } = useMyPermissions({ botId });
  const canManageFeatureFlags = hasBotPerms('manage_feature_flags');

  const { botSettings } = useBotSettings(botId);
  const isIntentBot = botSettings?.intentMatchEnabled;

  const { data: organizationsData, loading: organizationsLoading } = useQuery(MyOrganizationsDocument);
  const { data, loading } = useQuery(BotDocument, { variables: { botId: botId as string } });
  const { initialValues, loading: formLoading, onSubmit, isSubmitting } = useGeneralForm();

  const reserveAgainstArchivingInput = useMemo(
    () => ({
      name: 'reserveAgainstArchiving',
      value: initialValues?.bot?.reserveAgainstArchiving,
    }),
    [initialValues?.bot?.reserveAgainstArchiving],
  );

  const onClickReserveAgainstArchiving = useCallback(() => {
    onSubmit({ bot: { ...initialValues.bot, reserveAgainstArchiving: !initialValues.bot.reserveAgainstArchiving } });
  }, [initialValues, onSubmit]);

  const isLoading = loading || organizationsLoading || formLoading;

  if (!data?.bot || !organizationsData?.organizations || isLoading) {
    return <Loader size="large" />;
  }

  const bot = data.bot;
  const organizations = organizationsData.organizations;

  return (
    <Panel>
      <div className={styles.advancedLayout}>
        <h3 className={`${styles.sectionTitle} m-b-3`}>About workspace</h3>
        <div className={styles.contentTop}>
          <img className={styles.avatar} src={bot.avatarUrl || defaultBotAvatar} alt={`Avatar ${bot.name}`} />
          <div>
            <p>
              Bot name: <span className={styles.botName}>{bot.name}</span>
            </p>
            <p>
              Workspace name: <span className={styles.botName}>{bot.projectName}</span>
            </p>
          </div>
        </div>
        <div className={styles.contentBottom}>
          <p>Created:</p> <span>{format(data.bot.createdAt, DATE_FORMAT_DATE)}</span>
          <p>Languages: </p> <span>{getLanguageNames(bot?.languages ?? [])}</span>
          <p>Organization: </p> <span>{data.bot.organization.name}</span>
        </div>
        <Panel.Separator />
        <h3>Import and export workspace</h3>
        <Button className={styles.inlineBlock} to="../../import-export/export">
          Import & Export
        </Button>
        <Panel.Separator />
        <h3>Backup workspace</h3>
        <Button className={styles.inlineBlock} to="../../bot-backups">
          Create backup
        </Button>
        <Panel.Separator />
        <h3>Backup inbox</h3>
        <Button className={styles.inlineBlock} to="../../message-backups">
          Create backup
        </Button>
        <Panel.Separator />
        <h3>Clone workspace</h3>
        <p>Clone your workspace to make an exact duplicate.</p>
        <p> You also have the option to convert samples to intents (a.k.a. Kindly Plus) in the process.</p>
        <Button onClick={() => showCloneBot({ botId, botName: bot.name, isIntentBot, canManageFeatureFlags })}>
          Clone workspace
        </Button>
        <Panel.Separator />
        <h3>Move to organization</h3>
        <p>Move the workspace to another organizationt to transfer ownership.</p>
        <Button onClick={() => showTransferToOrganization({ botId, orgId: bot.organization.id, organizations })}>
          Transfer ownership
        </Button>
        <Panel.Separator />
        <h3>Create skill from workspace</h3>
        <p>You can create a skill from this workspace to utilize the content in another workspace.</p>
        <Button onClick={() => showCreateSkillFromBot({ botId, botName: bot.name })}>Create skill</Button>
        <Panel.Separator />
        {canManageFeatureFlags && (
          <>
            <h3 className="kindlyAdminManager">Translate bot content</h3>
            <p>Translate the bot content to another language.</p>
            <Button onClick={() => showTranslateBot({ botId })}>Translate bot content</Button>
            <Panel.Separator />
          </>
        )}
        <h3>Archive workspace</h3>
        <p>You may recover archived workspaces at any time if you need it later.</p>
        <Button color="warning" onClick={() => showArchiveBot({ botId, name: bot.name })}>
          Archive workspace
        </Button>
        {canManageFeatureFlags && (
          <>
            <Panel.Separator />
            <div className="m-t-2">
              <h3 className="kindlyAdminManager">Reserve against automatic archiving</h3>
              If the workspace has no activity for a time it will be automatically archived. The activity could be
              chatting with the bot or updating the Build content. If this does not suit your purposes you can reserve
              against automatic archiving here.
            </div>
            <div className={cx('m-t-2', styles.toggleWrapper)}>
              <ToggleSwitch.Switch
                input={reserveAgainstArchivingInput}
                status={RESERVE_AGAINTS_ARCHIVING_STATUS}
                onClick={onClickReserveAgainstArchiving}
              />
              {isSubmitting && <Loader className="m-l-1" />}
            </div>
          </>
        )}
        <Panel.Separator />
        <h3>Delete workspace</h3>
        <p>Deleted workspaces can not be recovered.</p>
        <Button
          color="warning"
          onClick={() => showDeleteBot({ botId, name: bot.name, organizationId: bot.organization.id })}
        >
          Delete workspace
        </Button>
      </div>
    </Panel>
  );
};

export default Advanced;
