import { useMutation, useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useToast } from 'frontend/hooks';

import { GET_SECURITY_SETTINGS, UPDATE_SECURITY_SETTINGS } from './graphql';

export default () => {
  const toast = useToast();
  const { botId } = useParams();

  const update = useCallback(() => toast.success('Settings updated'), [toast]);
  const [updateSettings] = useMutation(UPDATE_SECURITY_SETTINGS, { update });

  const { data, loading } = useQuery(GET_SECURITY_SETTINGS, { variables: { botId } });
  const initialValues = data?.bot;

  const onSubmit = useCallback(
    async (values) => {
      await updateSettings({
        variables: {
          botId,
          botInput: {
            ...pick(values, ['name', 'chatbubbleDomains', 'confidenceThreshold']),
          },
        },
      });
    },
    [botId, updateSettings],
  );

  return { loading, onSubmit, initialValues };
};
